import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Contact = props => (
  <Layout bodyClass="page-contact">
    <SEO title="Contact" />
    <div className="intro mt-5 mt-lg-0">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Contact Us</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="container pb-6">
      <div className="row">
        <div className="col-sm">
          <h5 className="form-title">Get in touch</h5>
          <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="form-group">
              <input type="text" className="form-control" name="name" id="name" placeholder="Name" required />
            </div>
            <div className="form-group">
              <input type="email" className="form-control" name="email" id="email" placeholder="Email" required />
            </div>
            <div className="form-group">
              <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
            </div>
            <div className="form-group">
              <textarea name="message" id="message" rows="5" className="form-control" placeholder="Message" required />
            </div>
            <button className="btn btn-primary form-control" type="submit">Send</button>
          </form>
        </div>
        <div className="col-sm">
          <br/>
          <dl className="details-list ">
            <div className="details-list__item">
              <dt className="details-list__dt">Email</dt>
              <dd className="details-list__dd">
                <a href='mailto:enquiries@tsncontractors.co.uk'>enquiries@tsncontractors.co.uk</a>
              </dd>
            </div>
            <hr/>
            <div className="details-list__item">
              <dt className="details-list__dt">Phone</dt>
              <dd className="details-list__dd">
                <a href='tel:07590 120413'>07590 120413</a> <br/><small className='color-contrast-medium'>Mon - Sat, 9AM - 5PM</small>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </Layout>
);

export default Contact;
